import { NextPageWithLayout } from "next";
import { addApolloState, initializeApollo } from "../lib/apollo/apollo-client";
import ShopLayout from "../components/layouts/ShopLayout";
import {
  AlterPerformanceCouncil,
  BackedByExperts,
  EveryStep,
  FeaturedIn,
  HeroSection,
  HomepageFaq,
  HowAlterWorks,
  JourneyWithAlter,
  RealFitnessCoach,
} from "../components/homepage";
import { gql } from "@apollo/client";
import {
  CardT,
  ExpertT,
  GalleryT,
  MediaT,
  TextT,
} from "../utils/contentfulTypes";
import StartJourneyCTA from "../components/sections/StartJourneyCTA";
import { Yotpo } from "../components/sections/YotpoReviews";
import { getFAQ, getGallery, getText } from "@/queries/contentfulQueries";

const GET_HERO = gql`
  query Hero {
    media(id: "uAJA9xKHM4bOPSKaUJvkp") {
      title
      headline
      video
      videoMobile
      imageMobile {
        url
      }
      imageDesktop {
        url
      }
    }
  }
`;

const GET_CARD_REPEATER = gql`
  query CardRepeater($id: String!) {
    repeater(id: $id) {
      headline
      itemCollection {
        items {
          ... on Card {
            headline
            text
            buttonText
            buttonLink
            image {
              height
              width
              url
            }
            imageMobile {
              height
              width
              url
            }
          }
        }
      }
    }
  }
`;

const GET_ALTER_PERFORMANCE_COUNCIL = gql`
  query PerformanceCouncil {
    repeater(id: "1qXyX0vIQ49GwkzjdfHIvF") {
      headline
      itemCollection {
        items {
          ... on AboutUsExpert {
            title
            name
            positions
            affiliations
            description
            image {
              url
            }
          }
        }
      }
      data
    }
  }
`;

type CardRepeaterT = {
  headline: string;
  itemCollection: {
    items: CardT[];
  };
};

export async function getStaticProps() {
  const apolloClient = initializeApollo();
  const context = { clientName: "contentful" };

  const { data: hero } = await apolloClient.query({
    query: GET_HERO,
    context,
  });

  const { data: journeyWithAlter } = await apolloClient.query({
    query: GET_CARD_REPEATER,
    variables: { id: "6Awq2ODdpmHdHlyoIb7ege" },
    context,
  });

  const backedByExpertsFrom = await getGallery("WCXyxSxENS9UNgD9P9ITO");

  const { data: howAlterWorks } = await apolloClient.query({
    query: GET_CARD_REPEATER,
    variables: { id: "5tWiBYvMr0yXAbX8eXO9sd" },
    context,
  });

  const quote = await getText("V3tqNkmtxNWtjkmgJJKm4");

  const { data: realFitnessCoach } = await apolloClient.query({
    query: GET_CARD_REPEATER,
    variables: { id: "4kKj5BwfZyxo44d9oUDtFI" },
    context,
  });

  const { data: performanceCouncil } = await apolloClient.query({
    query: GET_ALTER_PERFORMANCE_COUNCIL,
    context,
  });

  const { data: everyStep } = await apolloClient.query({
    query: GET_CARD_REPEATER,
    variables: { id: "2bAKqKBwSZmg3nbwirkqiY" },
    context,
  });

  const featuredIn = await getGallery("6KBeIdx5b4lNEt42ih7zsW")
  // console.log(featuredIn.gallery.imagesCollection.items);

  const faq = await getFAQ();

  return addApolloState(apolloClient, {
    props: {
      hero: hero.media,
      journeyWithAlter: journeyWithAlter.repeater,
      backedByExpertsFrom: backedByExpertsFrom,
      howAlterWorks: howAlterWorks.repeater,
      quote: quote,
      realFitnessCoach: realFitnessCoach.repeater,
      performanceCouncil: performanceCouncil.repeater.itemCollection.items,
      everyStep: everyStep.repeater,
      featuredIn: featuredIn,
      faq: faq,
    },
    revalidate: 60, // 1 minutes
  });
}

interface HomepageProps {
  hero: MediaT;
  journeyWithAlter: CardRepeaterT;
  backedByExpertsFrom: GalleryT;
  howAlterWorks: CardRepeaterT;
  quote: TextT;
  realFitnessCoach: CardRepeaterT;
  performanceCouncil: ExpertT[];
  everyStep: CardRepeaterT;
  featuredIn: GalleryT;
  faq: TextT[];
}

const Homepage: NextPageWithLayout<HomepageProps> = ({
  hero,
  journeyWithAlter,
  backedByExpertsFrom,
  howAlterWorks,
  quote,
  realFitnessCoach,
  performanceCouncil,
  everyStep,
  featuredIn,
  faq,
}) => {
  return (
    <div className="bg-white">
      <HeroSection data={hero} />
      <FeaturedIn
        headline={featuredIn.headline}
        images={featuredIn.imagesCollection.items}
      />
      <JourneyWithAlter
        headline={journeyWithAlter.headline}
        items={journeyWithAlter.itemCollection.items}
      />
      <BackedByExperts
        headline={backedByExpertsFrom.headline}
        items={backedByExpertsFrom.imagesCollection.items}
      />
      <HowAlterWorks
        headline={howAlterWorks.headline}
        items={howAlterWorks.itemCollection.items}
        quote={quote}
      />
      <RealFitnessCoach items={realFitnessCoach.itemCollection.items} />
      <div className="w-full h-8 bg-[#F6F6F1]" />
      <AlterPerformanceCouncil performanceCouncil={performanceCouncil} />
      <EveryStep
        headline={everyStep.headline}
        items={everyStep.itemCollection.items}
      />
      <Yotpo.InitWidgets />
      <Yotpo.Reviews />
      <HomepageFaq items={faq} />
      <StartJourneyCTA variant="dna" />
    </div>
  );
};

Homepage.getLayout = (page) => {
  return (
    <>
      <ShopLayout fill dark hideBlurAtTop>
        {page}
      </ShopLayout>
    </>
  );
};

export default Homepage;
